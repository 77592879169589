import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export const formatDateDayHours = (dateString: string): string => {
  const date = new Date(dateString);
  return format(date, "dd/MM/yyyy 'às' HH:mm'h'", { locale: ptBR });
};

export const getLocalDateString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

import { useEffect, useState } from "react";

import { TSwitchProps } from "./types";
import * as S from "./styles";

export const Switch: React.FC<TSwitchProps> = ({
  name,
  checked = false,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    setIsChecked((prev) => !prev);
    onChange?.(!isChecked);
  };

  return (
    <S.SwitchContainer>
      <S.HiddenCheckbox
        name={name}
        checked={isChecked}
        onChange={handleToggle}
      />

      <S.Slider checked={isChecked}>
        <span>{isChecked ? "Sim" : "Não"}</span>
      </S.Slider>
    </S.SwitchContainer>
  );
};

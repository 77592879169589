import { messageSpeechSuccessIcon, cautionIcon } from "../../assets";
import { Typography } from "../../components";
import * as S from "./styles";

export const DailyReview = ({ isDone = false }) => {
  return (
    <S.NoRevisionContainer gap={8} alignItems="center">
      <img src={isDone ? messageSpeechSuccessIcon : cautionIcon} />

      <Typography size={14}>
        {isDone
          ? "Você já revisou todos os assuntos para essa data."
          : "Não existe revisões para a data selecionada!"}
      </Typography>
    </S.NoRevisionContainer>
  );
};

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { ERoutesPath } from "../../../routes";
import {
  Button,
  Flex,
  Typography,
  Tag,
  SearchInput,
  ReviseLoading,
  Tooltip,
} from "../../../components";
import { scrollToTop } from "../../../helpers";
import api, { END_POINTS } from "../../../services/api";
import { AuthContext } from "../../../contexts/auth";
import { formatDateDayHours } from "../../../utils/date";
import {
  deleteBorderIcon,
  playBorderIcon,
  trophyBorderIcon,
  pinCheckedIcon,
  pinUncheckedIcon,
} from "../../../assets";
import { ConfirmDeleteModal } from "./ConfirmDelete";

import { ESimulateStatus, TSimulate } from "../types";

import * as S from "./styles";

export const SimulatesList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [simulates, setSimulates] = useState<Array<TSimulate>>([]);
  const [filteredSimulates, setFilteredSimulates] = useState<TSimulate[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [tooltip, setTooltip] = useState<number>();

  const idAccount = user.id_user;

  const handleNewTest = () => {
    navigate(ERoutesPath.SIMULADOS_NOVO);
  };

  const handleStartContinue = (simulateId: number) => {
    navigate(
      ERoutesPath.SIMULADO_RESPONDER.replace(":id", simulateId.toString())
    );
  };

  const getSimulates = async () => {
    try {
      setIsLoading(true);
      const simulateData = await api.get<Array<TSimulate>>(
        `${END_POINTS.simulates}/${idAccount}`
      );

      setSimulates(simulateData.data);
      setFilteredSimulates(
        [...simulateData.data].sort(
          (a, b) => Number(b.pinned) - Number(a.pinned)
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (simulateId: number) => {
    setConfirmDelete(simulateId);
  };

  const handlePinSimulate = async (simulateId: number) => {
    setIsLoading(true);
    await api.patch<Array<TSimulate>>(
      `${END_POINTS.simulateChangePinned}/${simulateId}`
    );

    await getSimulates();
    scrollToTop();
  };

  const handleFeedback = async (simulateId: number) => {
    navigate(ERoutesPath.SIMULADO_RESUMO.replace(":id", simulateId.toString()));
  };

  useEffect(() => {
    getSimulates();
  }, []);

  useEffect(() => {
    const filtered = simulates.filter((simulate) => {
      const formattedDate =
        simulate.start_at &&
        new Date(simulate.start_at).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        });

      const year =
        simulate.start_at &&
        new Date(simulate.start_at).getFullYear().toString();

      return (
        simulate.name.toLowerCase().includes(search.toLowerCase()) ||
        (formattedDate && formattedDate.includes(search)) ||
        (year && year.includes(search))
      );
    });

    setFilteredSimulates(filtered);
  }, [search]);

  return (
    <>
      <Typography color="#6E6893" family="Montserrat" size={20}>
        Meus simulados
      </Typography>

      <Flex direction="column" width="100%" gap={8}>
        {isLoading ? (
          <ReviseLoading />
        ) : (
          <>
            <Flex
              justifyContent="space-between"
              style={{
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <SearchInput
                placeholder="Buscar simulado"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => setSearch("")}
              />

              <Button size="small" onClick={handleNewTest}>
                Novo Simulado
              </Button>
            </Flex>

            <Flex direction="column" gap={8}>
              {filteredSimulates.map((simulate) => (
                <S.CardContainer key={simulate.id}>
                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Simulado
                    </Typography>
                    <Typography size={14}>{simulate.name}</Typography>
                  </Flex>

                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Criado em
                    </Typography>
                    <Typography size={14}>
                      {formatDateDayHours(simulate.created_at)}
                    </Typography>
                  </Flex>

                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Última atualização
                    </Typography>
                    <Typography size={14}>
                      {formatDateDayHours(simulate.updated_at)}
                    </Typography>
                  </Flex>

                  <Flex direction="column">
                    <Typography size={12} color="#6e6893" weight={500}>
                      Andamento
                    </Typography>
                    <div>
                      {simulate.status === ESimulateStatus.FINISHED && (
                        <Tag type="SUCCESS" text="Concluído" />
                      )}
                      {simulate.status === ESimulateStatus.CREATED && (
                        <Tag type="ERROR" text="Não iniciado" />
                      )}
                      {simulate.status === ESimulateStatus.STARTED && (
                        <Tag type="WARNING" text="Iniciado" />
                      )}
                      {simulate.status === ESimulateStatus.PAUSED && (
                        <Tag type="INFO" text="Pausado" />
                      )}
                    </div>
                  </Flex>

                  <S.ActionContainer gap={16} justifyContent="flex-end">
                    {simulate.status === ESimulateStatus.FINISHED ? (
                      <Flex
                        direction="row"
                        alignItems="center"
                        gap={4}
                        onClick={() => handleFeedback(simulate.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={trophyBorderIcon} height={20} />
                        <Typography size={14} color="#525252">
                          Resultado
                        </Typography>
                      </Flex>
                    ) : (
                      <Flex
                        direction="row"
                        alignItems="center"
                        gap={4}
                        onClick={() => handleStartContinue(simulate.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={playBorderIcon} height={20} />
                        <Typography size={14} color="#525252">
                          Continuar
                        </Typography>
                      </Flex>
                    )}

                    <Flex
                      direction="row"
                      alignItems="center"
                      gap={4}
                      onClick={() => handleDelete(simulate.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={deleteBorderIcon} height={20} />
                      <Typography size={14} color="#525252">
                        Excluir
                      </Typography>
                    </Flex>

                    <Flex
                      direction="row"
                      alignItems="center"
                      gap={4}
                      onClick={() => handlePinSimulate(simulate.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <Tooltip
                        title={`${
                          simulate.pinned ? "Desafixar" : "Fixar"
                        } simulado`}
                        placement="top"
                        open={tooltip === simulate.id}
                        onClose={() => setTooltip(undefined)}
                        onOpen={() => setTooltip(simulate.id)}
                        disableFocusListener
                      >
                        <img
                          src={
                            simulate.pinned ? pinCheckedIcon : pinUncheckedIcon
                          }
                          height={20}
                        />
                      </Tooltip>
                    </Flex>
                  </S.ActionContainer>
                </S.CardContainer>
              ))}
            </Flex>
          </>
        )}

        {confirmDelete && (
          <ConfirmDeleteModal
            modalDismiss={() => setConfirmDelete(undefined)}
            simulateId={confirmDelete}
            onCallback={getSimulates}
          />
        )}
      </Flex>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNavigate } from "react-router-dom-v5-compat";

import api from "../../../services/api";

import {
  Container,
  ContainerLoading,
  Item,
  TextWrapper,
  Title,
  Subtitle,
  ContentDetails,
} from "./styles";
import { QuestionContext, TQuestionContext } from "../../../contexts/question";
import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";
import { toast } from "react-toastify";

export const CorrectQuestionsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const { typeUser } = useContext(AuthContext);
  const { setSelectedQuestion } = useContext<TQuestionContext>(QuestionContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (typeUser !== "10") {
      navigate(ERoutesPath.ROOT);
      return;
    }

    handleGetReports();
  }, []);

  const handleGetReports = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await api.get(`/api/allReport`);

      setData(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleUpdateReports = async (id: any) => {
    setIsLoading(true);

    try {
      const body = {
        idReport: id,
      };

      await api.post(`/api/report`, body);
      toast.success("Correção realizada com sucesso!");
      handleGetReports();
    } catch (err) {
      toast.error(
        "Desculpe. Erro interno em nossos servidores, tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuestion = async (idQuestion: number) => {
    setIsLoading(true);

    try {
      const responseQuestion = await api.get(`/api/question/${idQuestion}`);

      setSelectedQuestion(responseQuestion.data);
      navigate(ERoutesPath.ADMINISTRAR_QUESTOES);
    } catch (err) {
      toast.error(
        "Desculpe. Erro interno em nossos servidores, tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <ContainerLoading>
            <CircularProgress />
          </ContainerLoading>
        ) : (
          <>
            {data.length > 0 ? (
              <>
                <div className="question">
                  <h3>Solicitação de correções</h3>
                </div>

                <ContentDetails>
                  {data.map((item: any) => {
                    return (
                      <Item>
                        <TextWrapper>
                          <Title>Questão {item.id_question}</Title>
                          <Title>Descrição: </Title>
                          <Subtitle>{item.description}</Subtitle>
                        </TextWrapper>

                        {item.verified !== 0 ? (
                          <>
                            <button
                              style={{
                                backgroundColor: "white",
                                color: "#029198",
                              }}
                            >
                              Verificado
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              style={{
                                backgroundColor: "white",
                                color: "#F18A8A",
                              }}
                            >
                              Não verificado
                            </button>
                            <button
                              style={{
                                backgroundColor: "#029198",
                                color: "white",
                              }}
                              onClick={() => handleUpdateReports(item.id)}
                            >
                              Verificar
                            </button>
                            <button
                              style={{
                                backgroundColor: "#173AAD",
                                color: "white",
                                marginLeft: 16,
                              }}
                              onClick={() => handleQuestion(item.id_question)}
                            >
                              Ver questão
                            </button>
                          </>
                        )}
                      </Item>
                    );
                  })}
                </ContentDetails>
              </>
            ) : (
              <>
                <div className="themes">
                  <div className="themesSuccess">
                    <p>Ainda não existem solicitações de correções {<br />}</p>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

import "rsuite/Calendar/styles/index.css";
import pt from "rsuite/locales/pt_BR";
import { Calendar, CustomProvider } from "rsuite";

import { useEffect } from "react";
import { getLocalDateString } from "../../utils/date";

import { TRevisionCalendarProps } from "./types";
import * as S from "./styles";

export const RevisionCalendar = ({
  reviewDays,
  onChangeDate,
}: TRevisionCalendarProps) => {
  const renderCell = (date: Date) => {
    const formattedDate = getLocalDateString(date);
    const reviewDay = reviewDays?.find((day) => day.date === formattedDate);

    if (!reviewDay) return null;

    let color;
    switch (reviewDay.status) {
      case "DONE":
        color = "#7DC579";
        break;
      case "LATE":
        color = "#FF8181";
        break;
      default:
        color = "#948CD8";
    }

    return <S.Circle color={color} />;
  };

  useEffect(() => {
    onChangeDate(new Date());
  }, []);

  return (
    <S.CalendarContainer>
      <CustomProvider locale={pt}>
        <Calendar
          compact
          renderCell={renderCell}
          onSelect={onChangeDate}
          style={{ width: "100%", maxWidth: "350px" }}
        />
      </CustomProvider>
    </S.CalendarContainer>
  );
};

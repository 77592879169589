import { useFormik } from "formik";

import { useState } from "react";

import { Input, ModalContainer, Button } from "../../../components";

import api from "../../../services/api";

import { TForgotPasswordModalProps } from "./types";

import * as S from "./styles";
import { toast } from "react-toastify";

export const ForgotPasswordModal = ({
  modalDismiss,
}: TForgotPasswordModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      forgotPasswordEmail: "",
    },
    onSubmit: () => {},
  });

  const handleForgotPassword = async () => {
    setIsLoading(true);

    try {
      const objectSend = {
        email: formik.values.forgotPasswordEmail,
      };

      await api.post("api/forgotPassword", objectSend);
      toast.success(
        "Solitição realizada com sucesso! Verifique sua caixa de email e também a caixa de spam."
      );
      modalDismiss();
    } catch (error) {
      toast.error(
        "Algo de errado. Confirme se o email fornecido está correto."
      );
    }

    setIsLoading(false);
  };

  return (
    <ModalContainer
      modalDismiss={() => modalDismiss()}
      title="Recuperar senha"
      subtitle="Insira seu e-mail abaixo. Enviaremos um e-mail explicando como você pode recuperar sua senha."
    >
      <S.StyledForm>
        <Input
          name="forgotPasswordEmail"
          type="email"
          placeholder="Insira seu e-mail"
          onChange={formik.handleChange}
          value={formik.values.forgotPasswordEmail}
          color="gray"
        />

        <Button
          isSecondary={false}
          onClick={() => {
            handleForgotPassword();
          }}
          isLoading={isLoading}
        >
          ENVIAR
        </Button>
      </S.StyledForm>
    </ModalContainer>
  );
};

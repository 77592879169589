import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Flex,
  InputText,
  MultipleSelect,
  Tooltip,
  Typography,
  Switch,
  ReviseLoading,
} from "../../../components";
import * as Yup from "yup";

import { infoCircleIcon } from "../../../assets";
import { sortArray } from "../../../helpers";
import api, { END_POINTS } from "../../../services/api";
import { amountOption, timeOption } from "./helper";

import {
  EAvailableFields,
  TFilterFormValues,
  TFilterOptions,
  TQuestionFilterProps,
} from "./types";
import * as S from "./styles";

export const QuestionFilter = ({
  fieldToShow,
  requiredFields,
  isFlashCard = false,
  isSimulate = false,
  showNotFound = false,
  isLoadingFilter,
  amountAvailable = amountOption,
  onSubmit,
}: TQuestionFilterProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<TFilterOptions>();
  const [matterOptions, setMatterOptions] = useState<Array<string>>([]);
  const [tooltip, setTooltip] = useState<string>();

  const initialValues: TFilterFormValues = {
    courses: [],
    matters: [],
    banks: [],
    years: [],
    difficulties: [],
    amount: "",
    time: "",
    hide_answered: false,
    name: undefined,
    only_wrong: false,
  };

  useEffect(() => {
    const handleQuestion = async () => {
      try {
        const filter = await api.get<TFilterOptions>(
          `${END_POINTS.filterQuestions}?flashcard=${isFlashCard}`
        );

        setFilterOptions(filter.data);
      } finally {
        setIsLoading(false);
      }
    };

    if (!filterOptions) handleQuestion();
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "O nome deve ter no mínimo 3 caracteres")
      .max(30, "O nome deve ter no máximo 30 caracteres")
      .required("Nome é obrigatório"),

    amount: Yup.string()
      .nullable()
      .test(
        "is-valid-amount",
        "A quantidade deve ser um número entre 15 e 100",
        (value) => {
          if (!value) return true;
          const numberValue = parseInt(value, 10);
          return !isNaN(numberValue) && numberValue >= 15 && numberValue <= 100;
        }
      )
      .required("Quantidade é obrigatório"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: isSimulate ? validationSchema : undefined,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => onSubmit(data),
  });

  const requiredFieldsFilled = () => {
    if (requiredFields) {
      return requiredFields.every((field) => {
        const value = formik.values[field];
        return typeof value === "string"
          ? !!value.trim()
          : Array.isArray(value) && value.length > 0;
      });
    }
    return true;
  };

  const isRequired = (field: EAvailableFields) =>
    requiredFields?.includes(field) ? "*" : "";

  useEffect(() => {
    if (filterOptions?.courses) {
      let values = [];
      for (const key of formik.values.courses) {
        values.push(...filterOptions.courses[key]);
      }

      const uniqueValues = values.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      setMatterOptions(uniqueValues);
    }

    if (formik.values.courses.length === 0) {
      formik.setFieldValue(EAvailableFields.MATTERS, initialValues.matters);
    }
  }, [formik.values.courses]);

  const handleResetForm = () => {
    formik.resetForm();
  };

  return (
    <S.Container>
      {isLoading || !filterOptions ? (
        <ReviseLoading />
      ) : (
        <S.Form onSubmit={formik.handleSubmit} noValidate>
          <S.Content>
            {fieldToShow.includes(EAvailableFields.COURSES) && (
              <MultipleSelect
                label={`Disciplina ${isRequired(EAvailableFields.COURSES)}`}
                name={EAvailableFields.COURSES}
                options={sortArray(Object.keys(filterOptions?.courses ?? []))}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.MATTERS) && (
              <MultipleSelect
                label={`Assunto ${isRequired(EAvailableFields.MATTERS)}`}
                name={EAvailableFields.MATTERS}
                options={sortArray(matterOptions)}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.BANKS) && (
              <MultipleSelect
                label={`Banca ${isRequired(EAvailableFields.BANKS)}`}
                name={EAvailableFields.BANKS}
                options={filterOptions.banks}
                formik={formik}
                upperCase
              />
            )}

            {fieldToShow.includes(EAvailableFields.YEARS) && (
              <MultipleSelect
                label={`Ano ${isRequired(EAvailableFields.YEARS)}`}
                name={EAvailableFields.YEARS}
                options={filterOptions.years}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.DIFFICULTIES) && (
              <MultipleSelect
                label={`Dificuldade ${isRequired(
                  EAvailableFields.DIFFICULTIES
                )}`}
                name={EAvailableFields.DIFFICULTIES}
                options={filterOptions.difficulties}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.AMOUNT) && (
              <MultipleSelect
                label={`Quantidade de ${
                  isSimulate ? "questões" : "cards"
                } ${isRequired(EAvailableFields.AMOUNT)}`}
                name={EAvailableFields.AMOUNT}
                options={amountAvailable}
                formik={formik}
                acceptOne
              />
            )}

            {/* {fieldToShow.includes(EAvailableFields.AMOUNT_OPEN) &&
              isSimulate && (
                <InputText
                  label={`Quantidade de questões ${isRequired(
                    EAvailableFields.AMOUNT_OPEN
                  )}`}
                  name={EAvailableFields.AMOUNT_OPEN}
                  formik={formik}
                  isNumeric
                  minLength={15}
                  maxLength={100}
                />
              )} */}

            {fieldToShow.includes(EAvailableFields.TIME) && (
              <MultipleSelect
                label={`Tempo ${isRequired(EAvailableFields.TIME)}`}
                name={EAvailableFields.TIME}
                options={timeOption}
                formik={formik}
                acceptOne
              />
            )}

            {fieldToShow.includes(EAvailableFields.SIMULATE_NAME) && (
              <InputText
                label={`Nome do simulado ${isRequired(
                  EAvailableFields.SIMULATE_NAME
                )}`}
                name={EAvailableFields.SIMULATE_NAME}
                formik={formik}
                minLength={3}
                maxLength={30}
              />
            )}

            <S.CheckboxContainer>
              <Flex gap={8} alignItems="center">
                {fieldToShow.includes(EAvailableFields.HIDE_ANSWERED) && (
                  <>
                    <Switch
                      name={EAvailableFields.HIDE_ANSWERED}
                      checked={formik.values.hide_answered}
                      onChange={(newCheckedValue) => {
                        formik.setFieldValue(
                          EAvailableFields.HIDE_ANSWERED,
                          newCheckedValue
                        );
                        if (
                          fieldToShow.includes(EAvailableFields.ONLY_WRONG) &&
                          newCheckedValue
                        ) {
                          formik.setFieldValue(
                            EAvailableFields.ONLY_WRONG,
                            false
                          );
                        }
                      }}
                    />

                    <Typography size={12}>
                      Ocultar questões já respondidas
                    </Typography>

                    <Tooltip
                      id="hide_answered"
                      title="Ao selecionar, não serão exibidas as questões respondidas anteriormente."
                      open={tooltip === "hide_answered"}
                      onClose={() => setTooltip(undefined)}
                      onOpen={() => setTooltip("hide_answered")}
                      disableFocusListener
                    >
                      <img
                        src={infoCircleIcon}
                        onClick={() => setTooltip("hide_answered")}
                        onMouseLeave={() => setTooltip(undefined)}
                      />
                    </Tooltip>
                  </>
                )}
              </Flex>
            </S.CheckboxContainer>

            <S.CheckboxContainer>
              <Flex gap={8} alignItems="center">
                {fieldToShow.includes(EAvailableFields.ONLY_WRONG) && (
                  <>
                    <Switch
                      name={EAvailableFields.ONLY_WRONG}
                      checked={formik.values.only_wrong}
                      onChange={(newCheckedValue) => {
                        formik.setFieldValue(
                          EAvailableFields.ONLY_WRONG,
                          newCheckedValue
                        );
                        if (
                          fieldToShow.includes(
                            EAvailableFields.HIDE_ANSWERED
                          ) &&
                          newCheckedValue
                        ) {
                          formik.setFieldValue(
                            EAvailableFields.HIDE_ANSWERED,
                            false
                          );
                        }
                      }}
                    />

                    <Typography size={12}>
                      Mostrar apenas questões que eu errei
                    </Typography>

                    <Tooltip
                      id="only_wrong"
                      title="Ao selecionar, serão exibidas as questões respondidas incorretamente."
                      open={tooltip === "only_wrong"}
                      onClose={() => setTooltip(undefined)}
                      onOpen={() => setTooltip("only_wrong")}
                      disableFocusListener
                    >
                      <img
                        src={infoCircleIcon}
                        onClick={() => setTooltip("only_wrong")}
                        onMouseLeave={() => setTooltip(undefined)}
                      />
                    </Tooltip>
                  </>
                )}
              </Flex>
            </S.CheckboxContainer>
          </S.Content>

          <Flex gap={16} style={{ padding: "0 2vh" }} width="100%">
            {showNotFound && !!formik.submitCount && !isLoadingFilter && (
              <Flex direction="column">
                <Typography color="#FF8181" weight={500}>
                  Tente outra combinação.
                </Typography>
                <Typography
                  color="#FF8181"
                  family="Montserrat"
                  size={12}
                  weight={300}
                >
                  Não foram encontradas questões para o filtro selecionado.
                </Typography>
              </Flex>
            )}

            <Flex gap={8} style={{ marginLeft: "auto" }}>
              <Button
                size="small"
                isSecondary
                type="reset"
                onClick={handleResetForm}
              >
                Limpar
              </Button>

              <Button
                type="submit"
                size="small"
                disabled={!requiredFieldsFilled() || isLoading}
                isLoading={isLoadingFilter}
              >
                {isSimulate ? "Criar simulado" : "Filtrar"}
              </Button>
            </Flex>
          </Flex>
        </S.Form>
      )}
    </S.Container>
  );
};

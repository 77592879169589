import { useEffect, useState } from "react";

import {
  messageSpeechInfoIcon,
  flipCardIcon,
  checkCircleIcon,
  errorCircleIcon,
  trophyIcon,
} from "../../assets";
import { Flex, QuestionNumber, Typography } from "../../components";

import {
  TCardActionsProps,
  TFlipCardContainerProps,
  TShowResultProps,
} from "./types";
import * as S from "./styles";

export const FlashCardDescription = () => (
  <S.FlashDescriptionContainer gap={12} alignItems="center">
    <img src={messageSpeechInfoIcon} />

    <Typography size={14} weight={500}>
      Revise de forma eficiente. Todas as sentenças extraídas direto das provas
      anteriores. Ideal para aprimorar seu aprendizado com agilidade e precisão!
    </Typography>
  </S.FlashDescriptionContainer>
);

export const FlipCardContainer = ({
  questionNumber,
  question,
  userAnswer,
  onHandleReport,
}: TFlipCardContainerProps) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  const handleFlipCard = () => {
    setIsFlipped((prev) => !prev);
  };

  useEffect(() => {
    setIsFlipped(!!userAnswer);
  }, [userAnswer]);

  const isCorrectAnswer =
    (userAnswer === "A" && question.feedback === "A") ||
    (userAnswer === "B" && question.feedback === "B");

  return (
    <S.FlipCard isFlipped={isFlipped} feedback>
      <div className="inner-card">
        {/* Frente do card */}
        <S.CardSide side="front">
          {!!userAnswer && (
            <S.FlipImg
              src={flipCardIcon}
              onClick={handleFlipCard}
              style={{ transform: "scaleX(-1)" }}
              isLeft
            />
          )}
          <QuestionNumber number={questionNumber} />
          <Flex height="100%" alignItems="center">
            <Typography align="center" weight={600}>
              {question.description}
            </Typography>
          </Flex>
        </S.CardSide>

        {/* Verso do card  */}
        <S.CardSide side="back" isTrue={isCorrectAnswer}>
          {userAnswer && (
            <>
              <S.FlipImg src={flipCardIcon} onClick={handleFlipCard} />

              <QuestionNumber number={questionNumber} />

              <Flex
                direction="column"
                height="100%"
                gap={4}
                style={{ marginTop: "8px" }}
              >
                <Typography
                  align="center"
                  family="Montserrat"
                  size={24}
                  weight={600}
                >
                  {question.feedback === "A" ? "Verdadeiro" : "Falso"}
                </Typography>

                <Flex justifyContent="center" alignItems="center" gap={4}>
                  <Typography
                    align="center"
                    family="Montserrat"
                    size={12}
                    weight={300}
                  >
                    Você respondeu:
                  </Typography>
                  <img
                    src={isCorrectAnswer ? checkCircleIcon : errorCircleIcon}
                    width={12}
                    height={12}
                  />
                  <Typography
                    align="center"
                    family="Montserrat"
                    size={12}
                    weight={300}
                  >
                    {userAnswer === "A" ? "Verdadeiro" : "Falso"}
                  </Typography>
                </Flex>

                <div style={{ margin: "auto" }}>
                  <Typography align="center" weight={400}>
                    {question.comment_correction}
                  </Typography>
                </div>
              </Flex>

              <Flex justifyContent="flex-end" width="100%">
                <S.ReportButton onClick={onHandleReport}>
                  Reportar erro
                </S.ReportButton>
              </Flex>
            </>
          )}
        </S.CardSide>
      </div>
    </S.FlipCard>
  );
};

export const CardActions = ({
  showNext,
  onSelectAlternative,
  onClickNextQuestion,
}: TCardActionsProps) => {
  return (
    <Flex gap={8} justifyContent="center" alignItems="center">
      {showNext ? (
        <S.Button color="#948CD8" onClick={onClickNextQuestion}>
          Próximo
        </S.Button>
      ) : (
        <>
          <S.Button color="#7DC579" onClick={() => onSelectAlternative("A")}>
            Verdadeiro
          </S.Button>

          <S.Button color="#FF8181" onClick={() => onSelectAlternative("B")}>
            Falso
          </S.Button>
        </>
      )}
    </Flex>
  );
};

export const ShowResult = ({ total, corrects, onFinish }: TShowResultProps) => {
  const allCorrect = total === corrects;

  return (
    <Flex justifyContent="center">
      <Flex direction="column" gap={12}>
        <S.FlipCard>
          <S.CardSide side="front">
            <Typography weight={400}>Resultado</Typography>

            <Flex
              direction="column"
              height="100%"
              justifyContent="center"
              alignItems="center"
              gap={8}
            >
              {allCorrect && <img src={trophyIcon} height={25} />}

              <Typography weight={600}>
                {allCorrect
                  ? "Você acertou todos os cartões!"
                  : `Você acertou ${corrects} de ${total} cartões!`}
              </Typography>

              <Typography family="Montserrat" size={12} weight={400}>
                {allCorrect
                  ? "Parabéns, você dominou essa revisão."
                  : "Continue revisando para melhorar ainda mais."}
              </Typography>
            </Flex>
          </S.CardSide>
        </S.FlipCard>

        <Flex justifyContent="center" alignItems="center">
          <S.Button color="#948CD8" onClick={onFinish}>
            Finalizar
          </S.Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

import { useContext, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import api, { END_POINTS } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ERoutesPath } from "../../routes";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  Button,
  Flex,
  PageTemplate,
  Typography,
  ReviseLoading,
} from "../../components";
import { scrollToTop } from "../../helpers";
import { TQuestionRevision, TReviewDays } from "./types";

import { RevisionCalendar } from "./Calendar";
import { RevisionCards } from "./Cards";
import { DailyReview } from "./DailyReview";

import * as S from "./styles";
import { getLocalDateString } from "../../utils/date";

export const RevisionsPage = () => {
  const { user, isPaidActive, isUserLogged } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataRevisions, setDataRevision] = useState<Array<TQuestionRevision>>(
    []
  );
  const [reviewDays, setReviewDays] = useState<Array<TReviewDays>>();
  const [selectedDay, setSelectedDay] = useState<Date>();

  const idAccount = user.id_user;
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
    if (!isUserLogged || !isPaidActive) navigate(ERoutesPath.ROOT);
    else handleGetQuestions();
  }, []);

  const extractUniqueReviewDays = (
    data: Array<TQuestionRevision>,
    dataReviewed: Array<TQuestionRevision>
  ): Array<TReviewDays> => {
    const today = getLocalDateString(new Date());

    const reviewDaysMap = new Map<string, TReviewDays>();

    data.forEach((item) => {
      const status = item.review_day < today ? "LATE" : "FUTURE";
      reviewDaysMap.set(item.review_day, { date: item.review_day, status });
    });

    dataReviewed.forEach((item) => {
      if (!reviewDaysMap.has(item.review_day)) {
        reviewDaysMap.set(item.review_day, {
          date: item.review_day,
          status: "DONE",
        });
      }
    });

    return Array.from(reviewDaysMap.values());
  };

  const handleGetQuestions = async () => {
    try {
      setIsLoading(true);

      const revisionData = await api.get(
        `${END_POINTS.validateReview}${idAccount}`
      );
      setDataRevision(revisionData.data);
      const reviewedData = await api.get(END_POINTS.revisionsReviewed);

      setReviewDays(
        extractUniqueReviewDays(revisionData.data, reviewedData.data)
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectStudies = () => {
    navigate(ERoutesPath.ASSUNTOS_ESTUDADOS);
  };

  const handleDate = (date: Date) => {
    setSelectedDay(date);
  };

  const filteredRevisions = useMemo((): Array<TQuestionRevision> | null => {
    if (selectedDay) {
      const filtered = dataRevisions.filter(
        (item) => getLocalDateString(selectedDay) === item.review_day
      );

      return filtered.length > 0 ? filtered : null;
    }
    return null;
  }, [selectedDay]);

  const filteredRevisionsOlder =
    useMemo((): Array<TQuestionRevision> | null => {
      const today = new Date();
      const filtered = dataRevisions.filter((item) => {
        const reviewDate = new Date(item.review_day);
        return reviewDate < today;
      });

      return filtered.length > 0 ? filtered.slice(0, 5) : null;
    }, [dataRevisions]);

  const revisionsBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Cronograma de revisões",
    },
  ];

  const isDayDone = (
    reviewDays?: Array<TReviewDays>,
    selectedDay?: Date
  ): boolean => {
    if (!reviewDays || !selectedDay) return false;
    const formattedDate = getLocalDateString(selectedDay);
    return reviewDays.some(
      (day) => day.date === formattedDate && day.status === "DONE"
    );
  };

  return (
    <PageTemplate
      title="Cronograma de revisões"
      breadcrumbsItems={revisionsBreadcrumb}
    >
      {isLoading ? (
        <ReviseLoading />
      ) : (
        <Flex width="100%" direction="column" gap={8}>
          <Button isSecondary onClick={handleRedirectStudies} size="small">
            Marcar assuntos estudados
          </Button>

          <S.ContentContainer gap={16} width="100%">
            <RevisionCalendar
              reviewDays={reviewDays}
              onChangeDate={handleDate}
            />

            <Flex direction="column" style={{ flex: "1" }}>
              {selectedDay && (
                <Typography size={12} color="#8F9BB3">
                  {format(selectedDay, "PPP", { locale: ptBR })}
                </Typography>
              )}

              {filteredRevisions && filteredRevisions.length > 0 ? (
                <RevisionCards revisions={filteredRevisions} />
              ) : (
                <Flex direction="column" gap={8}>
                  <DailyReview isDone={isDayDone(reviewDays, selectedDay)} />

                  {filteredRevisionsOlder && (
                    <>
                      <Typography size={12} color="#8F9BB3">
                        Revisões pendentes
                      </Typography>

                      <RevisionCards revisions={filteredRevisionsOlder} />
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          </S.ContentContainer>
        </Flex>
      )}
    </PageTemplate>
  );
};

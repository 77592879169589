export enum ESimulateStatus {
  CREATED = "CREATED",
  STARTED = "STARTED",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
}

export type TSimulate = {
  id: number;
  id_account: number;
  name: string;
  status?: ESimulateStatus;
  total_time: number;
  time_to_finish: number | null;
  start_at: string | null;
  finished_at: string | null;
  created_at: string;
  updated_at: string;
  pinned?: boolean;
};

import { ThemeProvider } from "styled-components";
import { HashRouter } from "react-router-dom-v5-compat";

import GlobalStyles from "./styles/global";
import lightTheme from "./styles/themes/light";
import {
  messageSpeechSuccessIcon,
  messageSpeechInfoIcon,
  messageSpeechErrorIcon,
  messageSpeechWarningIcon,
} from "./assets";

import AppProvider from "./contexts";

import { AppRoutes } from "../src/routes";
import { FloatingWhatsapp } from "./components";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <HashRouter>
        <ThemeProvider theme={lightTheme}>
          <AppProvider>
            <GlobalStyles />
            <AppRoutes />
            <ToastContainer
              icon={({ type, theme }) => {
                switch (type) {
                  case "info":
                    return <img src={messageSpeechInfoIcon} />;
                  case "error":
                    return <img src={messageSpeechErrorIcon} />;
                  case "success":
                    return <img src={messageSpeechSuccessIcon} />;
                  case "warning":
                    return <img src={messageSpeechWarningIcon} />;
                  default:
                    return null;
                }
              }}
            />
          </AppProvider>
        </ThemeProvider>
      </HashRouter>
      <FloatingWhatsapp />
    </>
  );
}

export default App;

import { Typography } from "../../atoms/Typography";
import { messageSpeechSuccessIcon } from "../../../assets";

import * as S from "./styles";

export const FeedbackForm = () => {
  const FORM_LINK = "https://forms.gle/NBHp4ZDadmPjYztq5";

  return (
    <S.Container gap={12}>
      <img src={messageSpeechSuccessIcon} />

      <Typography size={14} weight={500}>
        O que está achando da nossa plataforma? Compartilhe sua opinião e ganhe
        um ebook exclusivo sobre a análise estatística das provas. Basta clicar{" "}
        <S.Link href={FORM_LINK} target="_black">
          aqui
        </S.Link>
        . Sua opinião é essencial para nós.
      </Typography>
    </S.Container>
  );
};

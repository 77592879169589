import { useFormik } from "formik";
import { useState } from "react";

import { Checkbox } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

import { Input, ModalContainer, Link, Button } from "../../../components";
import { maskPhone } from "../../../utils/masks";
import api from "../../../services/api";

import { TRegisterModalProps } from "./types";
import * as S from "./styles";
import { TERMS_CONDITIONS } from "../../../constants";

export const RegisterModal = ({
  modalDismiss,
  handleRegisterUser,
}: TRegisterModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirm: false,
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      cellPhone: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      // handleSubmit(values.loginEmail, values.loginPassword);
    },
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const body = {
        name: formik.values.fullName,
        email: formik.values.email,
        cellphone: formik.values.cellPhone,
        password: formik.values.password,
      };

      await api.post("/api/createAccount", body);

      toast.success("Cadastro realizado com sucesso!");
      modalDismiss();
      if (handleRegisterUser) handleRegisterUser();
    } catch (err: any) {
      if (err.response.status === 403) {
        toast.error(`Desculpe. ${err.response.data.details}`);
      } else if (err.response.status === 422) {
        toast.error("Ops! Um ou mais itens precisam de atenção");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer modalDismiss={modalDismiss} title="Cadastre-se na Revise">
      <S.FormContainer onSubmit={formik.handleSubmit}>
        <Input
          type="name"
          name="fullName"
          placeholder="Informe seu nome completo *"
          onChange={formik.handleChange}
          value={formik.values.fullName}
          autoCapitalize="words"
          color="gray"
        />
        <Input
          name="email"
          type="email"
          placeholder="Informe seu e-mail *"
          onChange={formik.handleChange}
          value={formik.values.email}
          color="gray"
        />

        <Input
          name="cellPhone"
          type="phone"
          placeholder="Informe seu número de contato (81)99999-9999"
          maxLength={14}
          onChange={(e) => {
            formik.setFieldValue("cellPhone", maskPhone(e.target.value));
          }}
          value={formik.values.cellPhone ? formik.values.cellPhone : ""}
          color="gray"
        />

        <Input
          type={showPasswords.password ? "text" : "password"}
          name="password"
          placeholder="Senha *"
          onChange={formik.handleChange}
          value={formik.values.password}
          color="gray"
          icon={showPasswords.password ? VisibilityOffIcon : VisibilityIcon}
          onIconClick={() =>
            setShowPasswords((prev) => ({
              ...prev,
              password: !prev.password,
            }))
          }
        />

        <Input
          type={showPasswords.confirm ? "text" : "password"}
          name="confirmPassword"
          placeholder="Repetir senha *"
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
          color="gray"
          icon={showPasswords.confirm ? VisibilityOffIcon : VisibilityIcon}
          onIconClick={() =>
            setShowPasswords((prev) => ({
              ...prev,
              confirm: !prev.confirm,
            }))
          }
        />

        <S.CheckboxContainer>
          <Checkbox
            checked={acceptedTerms}
            onChange={() => setAcceptedTerms((prev) => !prev)}
          />
          Declaro que li e autorizo o uso de meus dados de acordo com os{" "}
          <Link href={TERMS_CONDITIONS} rel="noreferrer" target="_blank">
            Termos e condições
          </Link>
          .
        </S.CheckboxContainer>

        <S.ContainerButton>
          <Button
            type="submit"
            disabled={!acceptedTerms}
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Confirmar cadastro
          </Button>
          <Button
            isSecondary={true}
            type="submit"
            onClick={modalDismiss}
            disabled={isLoading}
          >
            Cancelar
          </Button>
        </S.ContainerButton>
      </S.FormContainer>
    </ModalContainer>
  );
};

import { useContext, useState } from "react";
import { useFormik } from "formik";

import { Input, ModalContainer, Button } from "../../../components";

import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";
import { TReportQuestionModalProps } from "./types";
import * as S from "./styles";
import { toast } from "react-toastify";

export const ReportQuestionModal = ({
  modalDismiss,
  idAccount,
  idQuestion,
}: TReportQuestionModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isUserLogged } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      feedback: "",
    },
    onSubmit: (values) => {
      handleReport(values.feedback);
    },
  });

  const handleReport = async ({ feedback }: any) => {
    setIsLoading(false);

    if (isUserLogged) {
      try {
        const body = {
          id_question: idQuestion,
          id_account: idAccount,
          feedback: formik.values.feedback,
        };

        await api.post(`api/student/report/question`, body);

        toast.success(
          "Obrigado! Nossos revisores irão analisar o seu feedback e realizar as devidas correções"
        );
        handleCloseModal();
      } catch (err) {
        toast.error(
          "Desculpe. Não foi possível registrar o seu reporte, tente novamente mais tarde."
        );
      }
    } else {
      toast.warning(
        "Desculpe. É necessário realizar o seu cadastro na revise para poder reportar uma questão."
      );
    }
  };

  const handleCloseModal = () => {
    modalDismiss();
  };

  return (
    <ModalContainer
      modalDismiss={handleCloseModal}
      title="Reportar questão"
      subtitle="Insira sua análise no campo abaixo, a equipe da revise irá analisar cuidadosamente."
    >
      <S.StyledForm onSubmit={formik.handleSubmit} autoComplete="off">
        <Input
          name="feedback"
          type="name"
          placeholder="Insira seu comentário *"
          onChange={formik.handleChange}
          value={formik.values.feedback}
          color="gray"
        />

        <Button type="submit" isLoading={isLoading}>
          Confirmar
        </Button>
      </S.StyledForm>
    </ModalContainer>
  );
};
